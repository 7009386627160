.about-menu {
    width: 19rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 60%;
    transform: translateY(-60%);
    left: 10vw;
  }
  
  .item {
    height: 3.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    font-weight: lighter;
  }
  
  .title {
    font-size: 1rem;
    font-weight: lighter;
    padding-right: 2.5rem;
  }
  
  .item.active .title {
    font-size: 1.5rem;
    font-weight: lighter;
  }
  
  .sub-container {
    width: 18.5rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 32%;
    right: 10vw;
    cursor: pointer;
    font-weight: lighter;
  }
  
  .active-subheading {
    cursor: default;
  }
  
  .item.active:nth-child(1) {
    background: linear-gradient(90deg, var(--transparent), var(--purple) 70%);
    border-radius: var(--radius-right);
  }
  .item.active:nth-child(2) {
    background: linear-gradient(90deg, var(--transparent), var(--blue) 70%);
    border-radius: var(--radius-right);
  }
  .item.active:nth-child(3) {
    background: linear-gradient(90deg, var(--transparent), var(--cyan) 70%);
    border-radius: var(--radius-right);
  }
  
  .sub-container-1.active-subheading h3,
  .sub-container-2.active-subheading h3,
  .sub-container-3.active-subheading h3 {
    border-radius: var(--radius-left);
  }
  
  .sub-container-1.active-subheading h3 {
    background: linear-gradient(90deg, var(--purple), var(--transparent) 100%);
  }
  .sub-container-2.active-subheading h3 {
    background: linear-gradient(90deg, var(--blue), var(--transparent) 100%);
  }
  .sub-container-3.active-subheading h3 {
    background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
  }
  
  .item.active {
    position: relative;
    cursor: default;
  }
  
  .p-container {
    display: none;
  }
  
  .active-subheading .p-container {
    display: block;
    font-size: 1rem;
    font-weight: lighter;
  }
  
  .icon-title-container {
    display: flex;
    gap: 1.2rem;
    margin-bottom: 0.5rem;
    cursor: default;
  }
  
  .icon {
    width: 3rem;
    height: 3rem;
  }
  
  .sub-container-1 h3,
  .sub-container-2 h3,
  .sub-container-3 h3 {
    position: relative;
    margin: 0.2rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
    font-weight: lighter;
  }
  
  @media (max-width: 700px) {
    .sub-container {
      right: 1rem;
    }
    .item.active .title {
      font-size: 1rem;
      font-weight: lighter;
    }
    .item {
      height: 2rem;
      font-weight: lighter;
    }
  }
  @media (max-width: 700px) {
    .sub-container {
      width: 15rem;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
    .title {
      font-size: 0.8rem;
      font-weight: lighter;
      padding-right: 2.5rem;
    }
    .active-subheading .p-container {
      font-size: 0.8rem;
      font-weight: lighter;
    }
    .icon-title-container,
    .icon {
      display: none;
    }
    h1 {
      font-size: 0.8rem;
      font-weight: lighter;
    }
    h2,
    h3,
    p {
      font-size: 0.7rem;
      font-weight: lighter;
    }
  }